import headerImage from './assets/images/header-image-3.png'

import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import './App.css';

import HeaderImageText from './components/Header/Header'
import CardWithCopy from './components/CardWithCopy/CardWithCopy';
import TableLinks from './components/TableLinks/TableLinks';

import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlRadio from '@shoelace-style/shoelace/dist/react/radio';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlOption from '@shoelace-style/shoelace/dist/react/option';
import SlSelect from '@shoelace-style/shoelace/dist/react/select';

import { useMediaQuery } from "react-responsive";



import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

// override with custom styles
import './Styles.css';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');


function App() {

  const isSmallScreen = useMediaQuery({ maxWidth: 500 });

  // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  
  // console.log("isDesktopOrLaptop:", isDesktopOrLaptop);
  // console.log("isBigScreen:", isBigScreen);
  // console.log("isTabletOrMobile:", isTabletOrMobile);
  // console.log("isPortrait:", isPortrait);


  const [isWaitingAdv, setIsWaitingAdv] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [apiKey, setApiKey] = useState(Cookies.get('APIKEY') || '');

  const [inputMessage, setInputMessage] = useState('')
  const [clusters, setClusters] = useState([]);
  const [selectedClusterName, setSelectedClusterName] = useState('');
  const [results, setResults] = useState(["", "", ""]);

  const allCommChannels = ["Posta elettronica", "Post Instagram", "Storia Instagram"];
  const [selectedChannel, setSelectedChannel] = useState(allCommChannels[0]);

  // id of the history element to show in input/output fields
  const [showHistoryId, setShowHistoryId] = useState('');

  // counter of history updates only to trigger side drawer refresh
  const [historyUpdates, setHistoryUpdates] = useState(0);

  const apiCallError = useRef(null);
  const apiCallWarningMissingInputMessage = useRef(null);
  const apiCallWarningMissingSelectedCluster = useRef(null);


  const systemDarkMode = useMediaQuery({ query: '(prefers-color-scheme: dark)' });
  
  // Initialize dark mode state  
  useEffect(() => {
    const appThemeCookies = Cookies.get('appTheme') || ''
    if (appThemeCookies === 'light') {
      setIsDarkMode(false)
    } else if (appThemeCookies === 'dark') {
      setIsDarkMode(true)
    } else {
      setIsDarkMode(systemDarkMode)
    }

    
  }, [systemDarkMode]);

  // load clusters information as soon as apiKey is available
  useEffect(() => {
    callGetClustersInfo();
  }, [apiKey])

  // load history id information when showHistoryId changes
  useEffect(() => {
    callGetHistoryId();
  }, [showHistoryId])
  


  const toggleDarkMode = (checked) => {
    setIsDarkMode(checked);
    Cookies.set('appTheme', checked ? 'dark' : 'light');
  };


  const getClusterByName = (clusterName) => {
    return clusters.find(el => el.name === clusterName);
  }

  const getLastWord = (text) => {
    const words = text.trim().split(/\s+/);
    return words[words.length - 1];
  }


  const callGetHistoryId = () => {
        
    if (apiKey.length === 0 || showHistoryId.length === 0) {
        return  // wait for a valid API key
    }
    
    const url = `/api/frontend-utils/v1/history/get_by_id?api_key=${apiKey}&element_id=${showHistoryId}`;
    fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            if (!apiCallError.current.open) { apiCallError.current.toast(); }
            console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
        }
    })
    .then((data) => {
      setInputMessage(data.body.input_message);
      setSelectedClusterName(data.body.cluster_name);
      setSelectedChannel(data.body.channel);
      setResults(data.body.results);
    })
    .catch((error) => {
        if (!apiCallError.current.open) { apiCallError.current.toast(); }
        console.error('Error:', error);
    });
  }


  const callAddToHistory = (inputMessageSent, selectedChannelSent, selectedClusterSent, adv_results) => {
    // get user name from cookies
    const userName = Cookies.get('USERNAME')

    // add the most recent call to the history
    fetch(`/api/frontend-utils/v1/history/add_data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            api_key: apiKey,
            user_name: userName,
            data: {
              "input_message": inputMessageSent,
              "channel": selectedChannelSent,
              "cluster_name": selectedClusterSent.name,
              "cluster_summary": selectedClusterSent.summary,
              "cluster_content": selectedClusterSent.content,
              "results": adv_results
            },
        }),
    })
    .then((response) => {
      if (response.status === 200) {
        console.log(`Result of AddData call: ${response.json().body}`);
        setHistoryUpdates(historyUpdates+1);
        console.log(historyUpdates)
      } else {
          if (!apiCallError.current.open) { apiCallError.current.toast(); }
          console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
      }
    })
    .catch((error) => {
      if (!apiCallError.current.open) { apiCallError.current.toast(); }
      console.error('Error:', error);
    });
  }



  const callAdvertiser = () => {

    // check if advertiser is already running
    if (isWaitingAdv) {
      return
    }

    setIsWaitingAdv(true);

    // check if input message has been entered
    if (inputMessage.length === 0) {
      if (!apiCallWarningMissingInputMessage.current.open) { apiCallWarningMissingInputMessage.current.toast(); }
      setIsWaitingAdv(false);
      return
    }

    // get cluster content associated to the selected cluster name
    const selectedCluster = getClusterByName(selectedClusterName);
    // check if a cluster has been selected
    if (!selectedCluster) {
      if (!apiCallWarningMissingSelectedCluster.current.open) { apiCallWarningMissingSelectedCluster.current.toast(); }
      setIsWaitingAdv(false);
      return
    }

    // save inputs sent to API in different variable to ensure coherence when they will be added to history
    const inputMessageSent = inputMessage;
    const selectedChannelSent = selectedChannel;
    const selectedClusterSent = selectedCluster
    
    // call advertiser API with inputs inserted by user
    fetch(`/api/advertiser/v1/advertise`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            api_key: apiKey,
            input_message: inputMessageSent,
            cluster_summary: selectedClusterSent.summary,
            channel: selectedChannelSent
        }),
    })
    .then((response) => {
      if (response.status === 200) {
          return response.json();
      } else {
          if (!apiCallError.current.open) { apiCallError.current.toast(); }
          console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
          setIsWaitingAdv(false);
      }
    })
    .then((data) => {
      console.log("Consumption of LLM call", data.consumption);
      setResults(data.body);
      setIsWaitingAdv(false);
      setTimeout(() => {
        callAddToHistory(inputMessageSent, selectedChannelSent, selectedClusterSent, data.body);        
      }, 1000);
    })
    .catch((error) => {
      if (!apiCallError.current.open) { apiCallError.current.toast(); }
      console.error('Error:', error);
      setIsWaitingAdv(false);
    });
  };


  const callGetClustersInfo = () => {
    // get API key from cookies
    const apiKey = Cookies.get('APIKEY') || '';
    if (apiKey.length > 0) {
      // call API to get user name from API key
      const url = `/api/frontend-utils/v1/get_clusters_info?api_key=${apiKey}`;
      fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
          if (response.status === 200) {
              return response.json();
          } else {
              if (!apiCallError.current.open) { apiCallError.current.toast(); }
              console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
          }
      })
      .then((data) => {
          setClusters(data.body);
          console.log(data.body[0].contacts);
      })
      .catch((error) => {
          if (!apiCallError.current.open) { apiCallError.current.toast(); }
          console.error('Error:', error);
      });
    }
  }



  return (
    <div className={isDarkMode ? 'dark-mode sl-theme-dark sl-theme-dark-greenday' : 'sl-theme-list sl-theme-greenday'} style={{ height: 'inherit' }}>

      <div className="App">
        
        <header className="App-header">
          <HeaderImageText 
            imageSrc={headerImage} 
            imageAltText="Header cover image showing a city covered by nature" 
            title="Green Day" 
            descriptionFilePath="/docs/description-page.md"  // path relative to /public
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
            apiKey={apiKey}
            setApiKey={setApiKey}
            setShowHistoryId={setShowHistoryId}
            historyUpdates={historyUpdates}
          />
        </header>

        <div className="App-body">
          <div className="demo-content">
            
            <SlTextarea 
              label="Contenuto della campagna"
              value={inputMessage}
              placeholder="TravelEco ti consente di garantire la sostenibilità della tua struttura..."
              resize="none"
              rows="5"
              spellcheck="true"
              onSlChange={(e) => {setInputMessage(e.target.value)}}
              style={{ width: '-webkit-fill-available', color: 'var(--on-surface)' }}
            />

            {clusters.length > 0 && (
              <SlSelect 
                className="clusters-select"
                label="Gruppo di strutture" 
                help-text="Seleziona un gruppo di strutture dalla lista"
              >
                {selectedClusterName.length > 0 && (
                  <div slot="prefix" className="clusters-select-icon" style={{ color: "var(--on-surface)" }}>
                    <SlIcon name="houses" />
                    {getLastWord(selectedClusterName)}
                  </div>
                )}
                {clusters.map((item, index) => (
                  <SlOption 
                    id={item.name} 
                    key={item.name} 
                    value={index}  // key used internally by SlSelect 
                    className="clusters-option" 
                    onClick={(e) => {setSelectedClusterName(e.target.id)}}
                  >
                    <div slot="prefix" className="clusters-option-icon">
                      {getLastWord(item.name)}
                    </div>
                    {item.summary}
                  </SlOption>
                ))}
              </SlSelect>
            )}

            {/* {clusters.length > 0 && (
              <SlRadioGroup label="Seleziona un gruppo di strutture" value={selectedClusterName}>
                {clusters.map((element) => (
                  <SlRadioButton 
                    id={element.name}
                    key={element.name}
                    value={element.name}
                    onSlFocus={(e) => {setSelectedClusterName(e.target.id)}}
                  >
                    {element.name}
                  </SlRadioButton>
                ))}
              </SlRadioGroup>
            )} */}

            <SlRadioGroup label="Seleziona un canale di comunicazione" value={selectedChannel}>
              {allCommChannels.map((el) => (
                <>
                {isSmallScreen ? (
                  <SlRadio
                    id={el}
                    key={el} 
                    value={el}
                    onSlFocus={(e) => {setSelectedChannel(e.target.id)}}
                    style={{ margin: '8px 0' }}
                  >
                    {el}
                </SlRadio>
                ) : (
                  <SlRadioButton 
                    id={el}
                    key={el} 
                    value={el}
                    pill
                    onSlFocus={(e) => {setSelectedChannel(e.target.id)}}
                  >
                    {el}
                  </SlRadioButton>
                )}
                </>
              ))}
            </SlRadioGroup>

            <SlButton 
              variant="primary"
              size="large"
              pill
              loading={isWaitingAdv}
              onClick={callAdvertiser}
              style={{ boxSizing: "content-box" }}
            >
              <SlIcon slot="prefix" name="stars" style={{ fontSize: "16pt" }}></SlIcon>
              Crea Campagne
            </SlButton>

            <div className="result-cards-group">
              
              <CardWithCopy 
                title="Proposta 1"
                content={results[0]}
                // content="Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri trasferibili “Letraset”, che contenevano passaggi del Lorem Ipsum, e più recentemente da software di impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum"
                isLoading={isWaitingAdv}
              />
              <CardWithCopy 
                title="Proposta 2"
                content={results[1]}
                // content="Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione."
                isLoading={isWaitingAdv}
              />
              <CardWithCopy 
                title="Proposta 3"
                content={results[2]}
                // content="Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri trasferibili “Letraset”, che contenevano passaggi del Lorem Ipsum, e più recentemente da software di impaginazione come Aldus PageMaker, che includeva versioni del Lorem Ipsum"
                isLoading={isWaitingAdv}
              />

            </div>

            {selectedClusterName && selectedClusterName.length > 0 && (
              <TableLinks data={getClusterByName(selectedClusterName).contacts} selectedClusterName={selectedClusterName} />
            )}

          </div>
        </div>
      
      </div>

      <SlAlert ref={apiCallError} variant="danger" duration="3000" closable>
        <SlIcon slot="icon" name="exclamation-square" />
        Errore durante la chiamata API.
      </SlAlert>

      <SlAlert ref={apiCallWarningMissingInputMessage} variant="warning" duration="3000" closable>
        <SlIcon slot="icon" name="exclamation-circle" />
        Non è stato inserito il contenuto della campagna.
      </SlAlert>

      <SlAlert ref={apiCallWarningMissingSelectedCluster} variant="warning" duration="3000" closable>
        <SlIcon slot="icon" name="exclamation-circle" />
        Non è stato selezionato il gruppo di strutture.
      </SlAlert>

    </div>
  );
}

export default App;
