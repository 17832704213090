import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import "primereact/resources/themes/lara-light-teal/theme.css";  // necessary to style the table
// import "primereact/resources/themes/lara-dark-teal/theme.css";  // necessary to style the table

import './TableLinks.css'

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


const TableLinks = ({ data, selectedClusterName }) => {

  // function to export data as Excel file
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(excelBlob, `Contatti Strutture ${selectedClusterName}.xlsx`);
  };

  const header = (
      <div className="table-links-header">
        <SlButton variant="text" onClick={exportToExcel}>
            <SlIcon slot="prefix" name="download" />
            Export All Data
        </SlButton>
      </div>
  );

  const urlFieldTemplate = (item) => {
    return <a href={item.Url} target="_blank" rel="noopener noreferrer">{item.Name}</a>
  };
  
  return (
    <div>
        <div className="table-links-title">Contatti delle strutture del gruppo selezionato</div>
        <DataTable 
          value={data} 
          size="small"
          stripedRows
          removableSort 
          scrollable scrollHeight="420px" 
          header={header}
          paginator rows={10} //rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ maxWidth: '40rem' }}
        >
            <Column header="#" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
            <Column field="Name" header="Name" body={urlFieldTemplate} sortable></Column>
        </DataTable>
    </div>
  );
};

export default TableLinks;
