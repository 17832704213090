import React from 'react';
import './ImageFading.css';


const ImageWithFadingBottom = ({ imageSrc, altText, fadeColor }) => {
  const containerStyle = {
    '--fade-color': fadeColor,
  };

  return (
    <div className="image-fading-container" style={containerStyle}>
      <img src={imageSrc} alt={altText} className="image-fading" />
    </div>
  );
};


export default ImageWithFadingBottom;
