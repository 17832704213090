import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import './SideDrawer.css';

import EmptyHistory from '../../assets/images/empty-history.png'

import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import SlBadge from '@shoelace-style/shoelace/dist/react/badge';


const SideDrawer = ({ isOpen, setIsOpen, apiKey, setShowHistoryId, historyUpdates }) => {

    const [history, setHistory] = useState([])

    const apiCallError = useRef(null)


    // load history
    useEffect(() => {
        callGetHistoryAll();
    }, [apiKey, historyUpdates])


    const callGetHistoryAll = () => {
        
        // get user name from cookies
        const userName = Cookies.get('USERNAME') || ''
        
        if (apiKey.length === 0 || userName.length === 0) {
            return  // wait for a valid API key
        }
        
        const url = `/api/frontend-utils/v1/history/get_all?api_key=${apiKey}&user_name=${userName}`;
        fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                if (!apiCallError.current.open) { apiCallError.current.toast(); }
                console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
        })
        .then((data) => {
            const userHistory = data.body.slice().reverse();
            setHistory(userHistory);
        })
        .catch((error) => {
            if (!apiCallError.current.open) { apiCallError.current.toast(); }
            console.error('Error:', error);
        });
    }


    const callDeleteId = (element_id) => {
        const url = `/api/frontend-utils/v1/history/delete_by_id?api_key=${apiKey}&element_id=${element_id}`;
        fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(`Result of DeleteId call: ${response.json()}`);
                // remove element given given id from history
                const newHistory = history.filter(item => item.id !== element_id);
                setHistory(newHistory);
            } else {
                if (!apiCallError.current.open) { apiCallError.current.toast(); }
                console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
            })
        .catch((error) => {
            if (!apiCallError.current.open) { apiCallError.current.toast(); }
            console.error('Error:', error);
        });
    }


    const callWipeHistory = () => {
        
        // get user name from cookies
        const userName = Cookies.get('USERNAME') || ''
        
        if (apiKey.length === 0 || userName.length === 0) {
            return  // wait for a valid API key
        }
        
        const url = `/api/frontend-utils/v1/history/wipe_user?api_key=${apiKey}&user_name=${userName}`;
        fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(`Result of WipeHistory call: ${response.json().body}`);
                setHistory([]);
            } else {
                if (!apiCallError.current.open) { apiCallError.current.toast(); }
                console.log(`Error in back-end response: ${response.status} - ${response.json()}`);
            }
            })
        .catch((error) => {
            if (!apiCallError.current.open) { apiCallError.current.toast(); }
            console.error('Error:', error);
        });
    }



    useEffect(() => {

        if (history.length > 0) {
            const sideDrawerBody = document.querySelector('.side-drawer-body');

            const handleShow = (event) => {
                if (event.target.localName === 'sl-details') {
                    [...sideDrawerBody.querySelectorAll('sl-details')].map(details => (details.open = event.target === details));
                }
            };

            // every time a detail is opened close all the other ones
            sideDrawerBody.addEventListener('sl-show', handleShow);

            // clean up event listener on component unmount
            return () => {
                sideDrawerBody.removeEventListener('sl-show', handleShow);
            };
        }
    }, [history]);
    
  
    function handleRequestClose(event) {
        // normal behavior + set the isOpen state to false
        setIsOpen(false);
        // this command is usually done in the onSlAfterHide event, but
        // doing so would conflict with the same named event in "Details" component
      }


  return (
    <>
        <SlDrawer 
            className="side-drawer"
            label="Cronologia delle richieste" 
            placement="start" 
            open={isOpen} 
            onSlRequestClose={handleRequestClose}
        >
            {history && (history?.length > 0) ? ( 
                <div className="side-drawer-body">
                    {history.map((element) => (
                        <SlDetails key={element.id} summary={element.title} open={false}>
                            
                            <strong>Contenuto della campagna</strong>
                            <br />
                            {element.input_message}
                            <br /><hr />
                            <strong>{element.cluster_name}</strong>
                            <br />
                            {element.cluster_summary}
                            <br /><hr />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <strong>Risultati</strong>
                                <SlBadge variant="neutral" pill>{element.channel}</SlBadge>
                            </div>
                            <ul>
                                {element.results && Object.entries(element.results).map((res, idx) => (
                                    <li key={idx}>{res[1]}</li>
                                ))}
                            </ul>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                <SlButton variant="danger" size="small" outline onClick={() => callDeleteId(element.id)}>
                                    <SlIcon slot="prefix" name="trash"/>
                                    Cancella
                                </SlButton>
                                <SlButton variant="primary" size="small" outline onClick={() => {setShowHistoryId(element.id); handleRequestClose();}}>
                                    Usa
                                    <SlIcon slot="suffix" name="check-circle"/>
                                </SlButton>
                            </div>
                        </SlDetails>
                    ))}
                </div>
            ) : (
                <div className="side-drawer-body-empty">
                    <img src={EmptyHistory} alt="Empty History Icon" style={{ width: "50%" }}/><br />
                    Prova a fare una richiesta al sistema.<br /><br />
                    Troverai qui lo storico di tutte le richieste effettuate.
                </div>
            )}

            <SlButton slot="footer" variant="danger" outline size="medium" onClick={callWipeHistory} style={{ width: '100%' }}>
                <SlIcon slot="prefix" name="x-octagon-fill" style={{ fontSize: "12pt" }}/>
                Cancella tutto
            </SlButton>

        </SlDrawer>

        <SlAlert ref={apiCallError} variant="danger" duration="3000" closable>
            <SlIcon slot="icon" name="exclamation-square" />
            Errore durante la chiamata API.
        </SlAlert>
    </>
  );
};

export default SideDrawer;
