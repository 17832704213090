import React from 'react';
import './CardWithCopy.css';

import SlCard from '@shoelace-style/shoelace/dist/react/card';
import SlSkeleton from '@shoelace-style/shoelace/dist/react/skeleton';
import SlCopyButton from '@shoelace-style/shoelace/dist/react/copy-button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';


function CardWithCopy ({ title, content, isLoading }) {

    const handleCopy = (value) => {
        // fallback copy-to-clipboard if shoelace internal method fails
        try {
            // create tmp element to copy text
            const textarea = document.createElement('textarea');
            textarea.value = value;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            console.log('Copied!');
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    }

  return (
    <SlCard className="card-with-copy">
        <div slot="header">
            {title}
            <SlCopyButton 
                value={content} 
                copy-label="Click to copy"
                success-label="Copied!"
                error-label="Whoops, your browser doesn't support this!"
                disabled={isLoading}
                feedback-duration={1000}
                className="btn-copy"
                onSlError={(e) => {console.log('error'); handleCopy(e.target.value);}}
            >
                <SlIcon slot="success-icon" name="check-lg" />
                <SlIcon slot="error-icon" name="check-lg" />
            </SlCopyButton>
        </div>
        {isLoading ? (
            <div className="skeleton-lines" >
                <SlSkeleton effect="sheen" style={{ "width": "90%" }} />
                <SlSkeleton effect="sheen" />
                <SlSkeleton effect="sheen" style={{ "width": "80%" }} />
                <SlSkeleton effect="sheen" style={{ "width": "50%" }} />
            </div>
        ) : (
            <>{content}</>
        )}
    </SlCard>
  );
};


export default CardWithCopy;