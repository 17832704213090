import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Cookies from 'js-cookie';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import './Header.css';

import { useMediaQuery } from "react-responsive";

import ImageWithFadingBottom from "../ImageFading/ImageFading";
import LoginDialog from '../LoginDialog/LoginDialog';
import SideDrawer from '../SideDrawer/SideDrawer';

import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';


const HeaderImageText = ({ imageSrc, imageAltText, title, descriptionFilePath, isDarkMode, toggleDarkMode, apiKey, setApiKey, setShowHistoryId, historyUpdates }) => {
  
  const [markdownText, setMarkdownText] = useState('');
  const [showLoginDialog, setShowLoginDialog] = useState((Cookies.get('APIKEY') || '').length === 0);
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 500 });

  
  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(descriptionFilePath); // Assuming the file path is a URL
        const text = await response.text();
        setMarkdownText(text);
      } catch (error) {
        console.error('Error fetching Markdown:', error);
      }
    };

    fetchMarkdown();
  }, [descriptionFilePath]);
  
  return (
    <>

        <div className="actions-group" style={{ left: "20px "}}>
          <SlButton 
            className="top-appbar-btn" 
            variant="default" 
            size="medium" 
            pill
            onClick={() => setShowSideDrawer(true)}
          >
            <SlIcon slot="prefix" name="layout-sidebar-inset" style={{ fontSize: "12pt" }} />
            {!isSmallScreen && (<span>Cronologia</span>)}
          </SlButton>
        </div>

      <div className="actions-group" style={{ right: "20px "}}>
        
        <div>
          <DarkModeSwitch
            checked={isDarkMode}
            onChange={toggleDarkMode}
            size={24}
          />
        </div>

        <SlButton className="top-appbar-btn" size="medium" pill onClick={() => setShowLoginDialog(true)}>
          <SlIcon slot="suffix" name="person-circle" style={{ fontSize: "14pt" }} />
          {!isSmallScreen && (<span>{Cookies.get('USERNAME') || "Cambia utente"}</span>)}
        </SlButton>

      </div>


      <LoginDialog 
        isOpen={showLoginDialog} 
        setIsOpen={setShowLoginDialog} 
        setApiKey={setApiKey}
      />

      <SideDrawer 
        isOpen={showSideDrawer} 
        setIsOpen={setShowSideDrawer}
        apiKey={apiKey} 
        setShowHistoryId={setShowHistoryId}
        historyUpdates={historyUpdates}
      />

      
      <ImageWithFadingBottom 
        imageSrc={imageSrc} 
        altText={imageAltText} 
        fadeColor="var(--surface)"
      />
      <div className="header-image-text-title">
        Green<img src='/title_logo.png' alt='website logo' style={{ height: "max(7vw, 32pt)" }} />Day
        {/* <span>{title}</span> */}
      </div>

      {isSmallScreen ? (
        <SlDetails summary="Webapp instructions" style={{ width: '75%', marginTop: '20px', color: 'var(--on-surface)' }}>
          <ReactMarkdown className="header-image-text-description">{markdownText}</ReactMarkdown>
        </SlDetails>
      ) : (
        <ReactMarkdown className="header-image-text-description">{markdownText}</ReactMarkdown>
      )}
    </>
  );
};

export default HeaderImageText;
